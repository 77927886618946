<template>
  <div class="lg:hidden xs:px-4 sm:px-6 lg:px-8" aria-hidden="true">
    <div class="section-divider mx-auto" />
  </div>
</template>

<style lang="scss">
.section-divider {
  @apply w-full h-[10px];
  background: url('./images/divider.svg') left center repeat-x;
}
</style>
